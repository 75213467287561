<template>
  <div>
    <v-card
      flat
    >
      <v-card-text>
        <dashboard
          :uppy="uppy"
          :props="dashboardProps"
        />
      </v-card-text>
      <v-card>
        <v-alert
          v-model="alertFailure"
          type="error"
          v-html="submitError"
        />
      </v-card>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <v-btn
        flat
        color="primary"
        @click="closez"
      >
        Cancel
      </v-btn>
      <v-btn
        v-show="!canSendSsm || loading"
        :loading="loading"
        :disabled="loading || files.length === 0"
        color="primary"
        @click="submit"
      >
        {{ submitText }}
        <span
          slot="loader"
          class="custom-loader"
        >
          <v-icon light>cached</v-icon>
        </span>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { createPostParams } from '@/rest'
import { DateTime } from 'luxon'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { Dashboard } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const debugLogger = {
  debug: (...args) => {}, // console.debug(`[Uppy] [${getTimeStamp()}]`, ...args),
  warn: (...args) => console.warn(`[Uppy] [${getTimeStamp()}]`, ...args),
  error: (...args) => console.error(`[Uppy] [${getTimeStamp()}]`, ...args),
}

function getTimeStamp () {
  return DateTime.local().setZone('Asia/Kuala_Lumpur').toLocaleString(DateTime.TIME_24_WITH_SECONDS)
}

export default {
  components: {
    Dashboard,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      input: null,
      agree: null,
      files: [],
      upload: true,
      failed: [],
      successful: [],
      loading: false,
      alertFailure: false,
      alertSuccess: false,
      canSendSsm: false,
      apiError: null,

    }
  },

  computed: {
    uppy: function () {
      const uppy = new Uppy({
        id: 'cms',
        autoProceed: false,
        allowMultipleUploadBatches: false,
        logger: debugLogger,
        restrictions: {
          maxFileSize: null,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024 * 4,
          maxNumberOfFiles: 1,
          minNumberOfFiles: null,
          allowedFileTypes: ['application/pdf', 'video/*', 'image/*', '.jpg', '.jpeg', '.png', '.gif'],
          requiredMetaFields: [],
        },
        meta: {},
        onBeforeFileAdded: (currentFile, files) => {},
        onBeforeUpload: (files) => {},
        locale: {},
        infoTimeout: 5000,
      }).use(Tus, {
        endpoint: 'https://onesys.onexox.my/files/',
        retryDelays: [0, 1000, 3000, 5000],
      })

      uppy.on('file-added', () => {
        this.files = this.uppy.getFiles()
      })

      uppy.on('files-added', () => {
        this.files = this.uppy.getFiles()
      })

      uppy.on('file-removed', (file, reason) => {
        this.files = this.uppy.getFiles()
        if (!this.files.length) {
          this.reset()
        }
      })

      return uppy
    },
    dashboardProps: function () {
      return {
        id: 'Dashboard',
        metaFields: [],
        trigger: null,
        inline: true,
        width: 700,
        height: 180,
        showLinkToFileUploadResult: false,
        showProgressDetails: false,
        hideUploadButton: true,
        hideRetryButton: true,
        hidePauseResumeButton: true,
        hideCancelButton: true,
        hideProgressAfterFinish: false,
        note: 'PDF only with a total size of 4 MB',
        closeModalOnClickOutside: false,
        closeAfterFinish: false,
        disableStatusBar: true,
        disableInformer: false,
        disableThumbnailGenerator: false,
        disablePageScrollWhenModalOpen: true,
        animateOpenClose: true,
        fileManagerSelectionType: 'files',
        proudlyDisplayPoweredByUppy: false,
        onRequestCloseModal: () => {},
        showSelectedFiles: true,
        showRemoveButtonAfterComplete: false,
        showNativePhotoCameraButton: false,
        showNativeVideoCameraButton: false,
        browserBackButtonClose: false,
        theme: 'dark',
        autoOpenFileEditor: false,
        disableLocalFiles: false,
        disabled: !this.upload,
      }
    },
    submitText: function () {
      return this.alertFailure ? 'Re-submit' : 'Submit'
    },

    submitError: function () {
      let error = 'Error submit the document!'

      if (!this.failed.length) {
        error += '<br>' + this.apiError
      } else {
        error += '<ul>'
        this.failed.forEach((file) => {
          error += '<li>[' + file.name + ']: ' + file.error + '</li>'
        })
        error += '</ul>'
      }
      return error
    },
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },

  mounted: function () {
    this.input = this.value
  },
  watch: {
    canSendSsm (val) {
      if (!val) return
      this.sendSsmDocument()
    },
  },

  methods: {
    async sendSsmDocument () {
      const params = createPostParams({
        action: 'upload3rdParty',
        payload: {
          files: this.upload ? this.successful : [],
          key: this.title,
        },
      })
      this.loading = true
      this.alertFailure = false
      this.alertSuccess = false
      try {
        await this.$rest.post('postAction.php', params)
        this.alertSuccess = true
        this.alertFailure = false
        this.canSendSsm = true
      } catch (e) {
        this.alertFailure = true
        this.alertSccess = false
      }
      this.submit()
      this.closez()
      this.loading = false
    },
    submit () {
      const failed = this.failed.length
      const files = this.uppy.getFiles()

      this.loading = true
      this.alertFailure = true
      this.alertSuccess = false
      if (!this.apiError) this.reset()

      if (this.upload && files.length > 0 && !this.apiError) {
        if (failed) {
          this.uppy.retryAll().then((result) => {
            this.successful = result.successful
            this.failed = result.failed
            // this.alertSuccess = this.successful.length && !this.failed.length
            this.alertFailure = this.failed.length
            this.loading = false
            this.canSendSsm = !this.failed.length
            this.$emit('opensnackbar2', true)
          })
        } else {
          this.uppy.upload().then((result) => {
            this.successful = result.successful
            this.failed = result.failed
            // this.alertSuccess = this.successful.length && !this.failed.length
            this.alertFailure = this.failed.length
            this.loading = false
            this.canSendSsm = !this.failed.length
            this.$emit('opensnackbar2', true)
          })
        }
      } else {
        this.loading = false
        this.apiError = null
        this.canSendSsm = true
      }
    },
    reset () {
      this.apiError = null
      this.alertFailure = false
      this.alertSuccess = false
      this.failed = []
      this.successful = []
    },
    closez: function () {
      this.$emit('closeDialog', false)
    },
  },
}
</script>
    <style>
      .custom-loader {
        animation: loader 1s infinite;
        display: flex;
      }
      @-moz-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @-webkit-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @-o-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    </style>
